.main_mode
  width: 100%

  .main_info
    margin-top: 30px
    display: flex
    margin-bottom: 45px
    position: relative


    .close_btn_position
      position: absolute
      right: 0
      cursor: pointer
      top: 0


    .close_btn
      width: 20px
      height: 20px
      position: absolute
      right: 0
      cursor: pointer
      top: 0
      background-image: url("../../../../../img/close/base.svg")

      &:hover
        background-image: url("./../../../../../img/close/hover.svg")


      &:active
        background-image: url("./../../../../../img/close/active.svg")


    .avatar
      margin-right: 200px

    .main_info_name
      .name
        font-family: RobotoCondensed
        font-size: 38px
        color: black
        margin-bottom: 10px

      .t_number
        color: #808080
        letter-spacing: 2.7px
        font-size: 17px
        line-height: 26px
        text-transform: uppercase
        font-family: RobotoCondensedLight


        span
          font-family: RobotoCondensedLight
          letter-spacing: 0.7px
          color: #1a1a1a
          font-size: 22px



  .block
    margin-bottom: 15px
    .title
      font-size: 24px
      margin-bottom: 15px
      font-family: RobotoCondensed

    .grid_block
      color: #808080
      display: grid
      grid-template-columns: 1fr 1fr

      span
        margin-bottom: 5px
        font-family: RobotoCondensedLight
        letter-spacing: 0.7px
        color: #1a1a1a
        font-size: 22px

        span
          color: grey
          letter-spacing: 2.7px
          font-size: 17px
          line-height: 26px
          text-transform: uppercase

      &.fr1
        grid-template-columns: 1fr


  .mgB2
    margin-bottom: 5px
    font-family: RobotoCondensedLight
    letter-spacing: 0.7px
    color: #1a1a1a
    font-size: 22px
    display: block