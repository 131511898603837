.base_wrap
  width: 100%
  height: 100%
  display: flex
  padding-left: 80px

  .menu_wrap
    width: 80px
    height: 100%
    position: fixed
    left: 0
    z-index: 2


  .body
    display: flex
    flex-direction: column
    width: 100%
    z-index: 1

    .header
      width: 100%
      height: 74px
      align-items: center
      padding: 20px 13px 20px 80px
      display: flex
      justify-content: space-between
      z-index: 3
      background-color: #f6f3f1
      position: fixed
      right: 0



      .ring
        display: flex
        height: 100%
        align-items: center
        cursor: default

        &:before
          content: ""
          width: 16px
          height: 19px
          margin-right: 5px
          background: url("../../img/ring/ring_base.png") no-repeat


        &:hover:before
          background: url("../../img/ring/ring_hover.png") no-repeat

        &:active:before
          background: url("../../img/ring/ring_active.png") no-repeat

        &.new
          cursor: pointer

          &:before
            background: url("../../img/ring/ring_new.png") no-repeat

          &:hover:before
            background: url("../../img/ring/ring_new_hover.png") no-repeat

          &:active:before
            background: url("../../img/ring/ring_new_active.png") no-repeat


        &>span
          font-size: 17px
          color: black
          font-family: RobotoCondensed

      .name
        position: relative

        .name_span
          font-size: 17px
          line-height: 24px
          letter-spacing: 5px
          cursor: pointer
          font-family: RobotoCondensed
          position: relative
          text-transform: uppercase
          color: black

          &:active
            color: #ffd200

          &:hover
            color: #dabe78

          &:before
            content: ""
            display: block
            position: absolute
            bottom: -2px
            left: -1px
            right: 6px
            border-bottom: 2px dotted
            margin-right: 7px


    .main_block
      background-color: #f6f3f1
      height: 100%
      padding: 74px 15px 15px 25px
      z-index: 2




