#NewComp
  position: relative

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .areaAdmin
    height: 150px


  .editUserSelect
    width: 350px
    border: 1px solid #ccc
    color: #1a1a1a
    height: 44px
    display: block
    border-radius: 2px
    box-sizing: border-box
    font-size: 19px
    line-height: 24px
    font-weight: 300
    letter-spacing: 0.9px
    outline: none
    transition: border-color 0.15s ease,background 0.15s ease
    font-family: RobotoCondensedLight

    .ant-select-selector
      height: 44px
      box-shadow: 0 0 0 1px #fff
      background: #faf8f7 linear-gradient(180deg,rgba(40,40,40,0.17) 0%,rgba(40,40,40,0) 13%,rgba(40,40,40,0) 17%)

      .ant-select-selection-item
        line-height: 42px

  .ant-select-dropdown
    .ant-select-item
      height: 30px
