html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline

html, body
  width: 100%
  height: 100%
  min-width: 1024px
  font-family: RobotoCondensed

body
  background-color: #f6f3f1

#root, .container
  width: 100%
  height: 100%

.container
  display: flex
  background: #f6f3f1
  flex-direction: column

@import '~antd/dist/antd.css'
@import "./fonts.sass"

/* custom styles */

.row_space_between
  display: flex
  justify-content: space-between
  align-items: center

.text_hover
  color: black

.text_active
  color: black

.text_base
  color: black



.text_main_1
  color: #000100 !important

  &:active
    color: #d9a82b !important
    &:before
      border-color: #d9a82b !important

  &:hover
    color: #cc9814 !important
    &:before
      border-color: #cc9814 !important




.text_main_2
  color: #000100 !important

  &:active
    color: #e4b251 !important

  &:hover
    color: #d9a82b !important

//.border_dashed
//  border-bottom: 1px dashed black !important

.text_active
  color: black

.text_base
  color: black

.row2
  display: flex
  flex-direction: row
  justify-content: space-around



.text_gold
  color: grey

  &:active
    color: #d9a82b !important

  &:hover
    color: #cc9814 !important


.block_color
  background: #ccc
  color: grey
  cursor: default


  &:hover
    background-color: rgba(128, 128, 128, 0.39) !important

.cancel_btn
  font-size: 14px
  line-height: 20px
  color: #000
  letter-spacing: 3px
  border-bottom: 1px dashed black
  cursor: pointer

  &:active
    border-bottom: 1px dashed #d9a82b

  &:hover
    border-bottom: 1px dashed #cc9814



.flexRow
  display: flex

.ant-table-thead
  text-transform: uppercase


.title_row
  background: rgba(128, 128, 128, 0.08) !important
  td
    background: rgba(128, 128, 128, 0.08) !important

  &:hover
    background: rgba(128, 128, 128, 0.08) !important
    td
      background: rgba(128, 128, 128, 0.08) !important



.title_must_row
  background: #f5f3f1 !important
  td
    background: #f5f3f1 !important

  &:hover
    background: #f5f3f1 !important
    td
      background: #f5f3f1 !important



.arrowTable
  background-image: url("./../src/img/arrowDown2/down.png")
  background-size: cover
  width: 20px
  height: 20px
  float: left
  cursor: pointer

.arrowTableUp
  transform: rotate(180deg)


.flex
  display: flex

.flexCenterVert
  display: flex
  align-items: center

.firstChar
  span:first-letter
    text-transform: capitalize

.pL35
  padding-left: 35px

.close_btn_global
  width: 20px
  height: 20px
  position: absolute
  right: 0
  cursor: pointer
  top: 10px

  background-image: url("./img/close/base.svg")

  &:hover
    background-image: url("./img/close/hover.svg")

  &:active
    background-image: url("./img/close/active.svg")



.global_close_btn
  position: absolute

  #close_btn_global
    cursor: pointer

    fill: black

    &:active
      fill: #d9a82b

    &:hover
      fill: #cc9814



.gridAdmin
  display: grid
  grid-template-columns: 1fr 3fr
  margin-bottom: 35px
  align-items: center
  grid-row-gap: 20px

  input
    width: 350px

.gridAdmin3
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr
  margin-bottom: 35px
  align-items: center
  grid-row-gap: 20px

  input
    width: 150px



.editUserSelect
  width: 350px
  border: 1px solid #ccc
  color: #1a1a1a
  height: 44px
  display: block
  border-radius: 2px
  box-sizing: border-box
  font-size: 19px
  line-height: 24px
  font-weight: 300
  letter-spacing: 0.9px
  outline: none
  transition: border-color 0.15s ease,background 0.15s ease
  font-family: RobotoCondensedLight

  .ant-select-selector
    height: 44px
    box-shadow: 0 0 0 1px #fff
    background: #faf8f7 linear-gradient(180deg,rgba(40,40,40,0.17) 0%,rgba(40,40,40,0) 13%,rgba(40,40,40,0) 17%)

    .ant-select-selection-item
      line-height: 42px

.ant-select-dropdown
  .ant-select-item
    height: 30px


.inpDate
  height: 44px
  box-sizing: border-box
  box-shadow: 0 0 0 1px #fff
  background: #faf8f7 linear-gradient(180deg, rgba(40, 40, 40, 0.17) 0%, rgba(40, 40, 40, 0) 13%, rgba(40, 40, 40, 0) 17%)
  font-size: 19px
  line-height: 24px
  font-weight: 300
  padding: 7px 14px 11px
  letter-spacing: 0.9px
  outline: none
  transition: border-color 0.15s ease, background 0.15s ease
  font-family: RobotoCondensedLight
  border: 1px solid #ccc


.topMilkShoww
  &:after
    content: ""
    height: 50px
    width: 100%
    top: 74px
    left: 0
    position: fixed
    background: linear-gradient(180deg, #f6f3f1 0%, rgba(255, 255, 255, 0))
    z-index: 1


.editAdminSelect
  width: 350px
  border: 1px solid #ccc
  color: #1a1a1a
  height: 44px
  display: block
  border-radius: 2px
  box-sizing: border-box
  font-size: 19px
  line-height: 24px
  font-weight: 300
  letter-spacing: 0.9px
  outline: none
  transition: border-color 0.15s ease,background 0.15s ease
  font-family: RobotoCondensedLight

  .ant-select-selector
    height: 44px
    box-shadow: 0 0 0 1px #fff
    background: #faf8f7 linear-gradient(180deg,rgba(40,40,40,0.17) 0%,rgba(40,40,40,0) 13%,rgba(40,40,40,0) 17%)

    .ant-select-selection-item
      line-height: 42px

  .ant-select-dropdown
    .ant-select-item
      height: 30px

.sendToApproveRowChild2
  .ant-select-selector
    //background-color: #ffd20042 !important

.fnsBtnsTable
  width: 100%
  min-width: 80px
  display: flex
  justify-content: flex-end

  svg
    height: 20px
    width: 20px
    margin-left: 20px

  svg:hover
    color: #ffd200
  svg:active
    color: #f2c037


.ant-empty-description
  display: block
  opacity: 1
  color: white
  position: relative


.baseColor
    --q-color-primary: #ffd200
    --q-color-secondary: #26a69a
    --q-color-accent: #9c27b0
    --q-color-positive: #21ba45
    --q-color-negative: #c10015
    --q-color-info: #31ccec
    --q-color-warning: #f2c037
    --q-color-dark: #1d1d1d