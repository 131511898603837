.text_area
  margin-top: 12px
  margin-bottom: 8px
  textarea
    width: 100%
    resize: none

    color: grey
    border-radius: 2px
    box-sizing: border-box
    font-size: 16px
    line-height: 24px
    font-weight: 300
    padding: 9px 14px 9px
    letter-spacing: 0.9px

    border: 1px solid #ccc
    box-shadow: 0 0 0 1px #fff
    background: #faf8f7 linear-gradient(180deg,rgba(40,40,40,0.17) 0%,rgba(40,40,40,0) 2%,rgba(40,40,40,0) 17%)
    outline: none
    transition: border-color 0.15s ease,background 0.15s ease
