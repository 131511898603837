.popUp2
  position: absolute
  padding:  10px 0 24px 10px
  background-color: white
  border: 1px solid rgba(0,0,0,.15)
  margin-top: 15px
  top: 20px
  right: 5px
  display: flex
  flex-direction: column
  border-radius: 4px
  box-shadow: 0 2px 10px rgb(0 0 0 / 8%)

  &:before
    content: ""
    position: absolute
    width: 0
    height: 0
    border: 10px solid transparent
    border-bottom-width: 15px
    border-bottom-color: #fff
    right: 23px
    top: -20px



.notification_block
  max-height: 531px
  width: 440px
  overflow: auto
  margin-bottom: 20px



  .notification_block__item__wrap
    padding: 0 10px
    position: relative


    .notification_block__item
      padding: 25px 0 20px 0
      border-bottom: 1px solid #d9d9d9
      letter-spacing: 2.2px
      line-height: 18px
      margin-bottom: 5px

      span
        font-size: 16px
        line-height: 22px

      .message_def
        color: grey
        font-family: RobotoCondensedLight

        b
          font-family: RobotoCondensed
          color: black
          letter-spacing: 2.2px

      .message_bold
        font-family: RobotoCondensed
        color: black
        letter-spacing: 2.2px



      .reedClose
        background-image: url("../../../../img/close.svg")
        background-repeat: no-repeat
        background-size: contain
        width: 10px
        height: 10px
        position: absolute
        top: 7px
        right: 10px

      .block_date
        position: absolute
        top: 2px
        font-family: RobotoCondensedLight
        font-size: 12px
        float: right

.leftM
  margin-left: 5px

.actionSpan
  cursor: pointer
  font-size: 16px
  line-height: 22px
  letter-spacing: 2.2px
  color: grey
  text-transform: uppercase
  font-family: RobotoCondensedLight
  //padding-left: 26px


.simpleSvg
  &:hover
    .text_gold
      color: #cc9814 !important
    .svgPath
      fill: #cc9814

  &:active
    .text_gold
      color: #d9a82b !important
    .svgPath
      fill: #d9a82b