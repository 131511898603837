.userAvatar
  border-radius: 50%

.avatar
  position: relative

  .uploadLayer
    position: absolute
    background-color: rgba(0, 0, 0, 0.2)
    border-radius: 100%
    background:
      image: url("../../../../../../img/changeAvatar.png")
      repeat: no-repeat
      position: center
      size: 20%
    cursor: pointer

    display: flex
    align-items: center
    justify-content: center
    opacity: 0
    transition: opacity 0.3s

    &:hover
      opacity: 1


    span
      color: white
      font-size: 20px
      font-family: RobotoCondensedLight
      margin-top: 80px
