#settingsPage
  .title
    font-family: RobotoCondensed
    color: grey
    font-size: 22px
    text-transform: uppercase
    letter-spacing: 6.7px

  .btnPosition
    display: flex
    flex-direction: row-reverse
    margin-top: 30px

  .cancel_btn_wrap
    display: flex
    align-items: center
    margin-right: 50px

  .textCenter
    align-items: center

  .wrong_data
    color: grey
    font-size: 14px
    line-height: 17px
    font-weight: 300
    letter-spacing: 0.7px
    margin-left: -8px
    font-family: RobotoCondensedLight
    margin-right: 15px