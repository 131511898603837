.btn_gold
  cursor: pointer
  background: #ffd200
  border-radius: 2px
  //box-shadow: 0 0 0 1px #fff
  white-space: nowrap
  text-overflow: ellipsis
  height: 42px
  box-sizing: border-box
  padding: 12px 12px 10px 16px
  transition: background 0.15s ease
  font-size: 14px
  line-height: 20px

  letter-spacing: 2.8px
  outline: none
  color: #000
  text-decoration: none
  text-transform: uppercase
  font-family: RobotoCondensed, serif
  font-style: normal
  position: relative

  &:hover
    background: #ffc926

  &:active
    background: #ffda33

.error_red
  background: #ff7e47
  box-shadow: inset 0 0 0 1px #ff6933

  &:hover
    background: #ff7e47

  &:active
    background: #ff7e47
