.search
  display: flex
  align-items: center
  position: relative

  .searchShos
    position: relative

    .clear
      width: 15px
      height: 15px
      position: absolute
      top: 13px
      right: 7px
      cursor: pointer



  .inputZone
    width: 300px

  .blockResult
    background-color: white
    border: 1px solid rgba(0, 0, 0, 0.26)
    display: flex
    flex-direction: column
    background: #faf8f7 linear-gradient(180deg, rgba(40, 40, 40, 0.17) 0%, rgba(40, 40, 40, 0) 13%, rgba(40, 40, 40, 0) 17%)
    font-size: 19px
    padding: 7px 0px 7px
    border-radius: 2px
    position: absolute
    width: 300px
    z-index: 3

    span
      line-height: 28px
      padding: 0 14px
      font-family: RobotoCondensedLight

      &:hover
        background-color: rgba(128, 128, 128, 0.27)
        cursor: pointer

  .key
    font-size: 18px
    font-family: RobotoCondensedLight
    margin-right: 20px
    letter-spacing: 2.7px
    line-height: 26px

.calcStyle
  margin-left: 10px