.loginPageStyle
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  width: 100%

  .title
    text-align: center

  .btns
    display: flex
    justify-content: space-around

  .ant-row
    justify-content: space-evenly

  .center
    width: 535px

  .grid
    display: grid
    grid-template-columns: 106px 421px
    gap: 24px 0

    .input_label
      font-family: RobotoCondensedLight
      letter-spacing: 3px
      text-transform: uppercase
      color: #000
      align-self: center


    span
      align-self: center

    .btn_forget
      font-family: RobotoCondensed
      letter-spacing: 3px
      color: #000
      font-size: 14px
      cursor: pointer
      position: relative
      margin-right: 10px

      &:before
        content: ""
        border-bottom: 1px dashed #7b7978
        margin-right: 7px
        position: absolute
        bottom: -1px
        left: 2px
        right: 4px


  .wrong_data
    color: grey
    font-size: 14px
    line-height: 17px
    font-weight: 300
    letter-spacing: 0.7px
    width: 110px
    margin-left: -8px
    font-family: RobotoCondensedLight

  .row_column_title
    width: 106px


  .border_top
    border-top: 1px solid #b3b3b3
    padding-top: 24px