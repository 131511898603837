.name_full_block
  margin-bottom: 60px
  .name_full
    font-family: RobotoCondensed
    color: #000000
    font-size: 37px

  .status
    text-align: end
    cursor: pointer
    position: relative
    font-size: 18px


    .statusHover
      font-size: 18px
      &:hover
        color: #dabe78
      &:active
        color: #ffd200

  &.grid_name
    display: grid
    grid-template-columns: auto 1fr



.info p
  margin-bottom: 17px
  font-family: RobotoCondensedLight
  letter-spacing: 0.7px
  color: #1a1a1a
  font-size: 21px


  .title_key
    color: grey
    letter-spacing: 2.7px
    font-size: 17px
    line-height: 26px
    text-transform: uppercase

