#modal_wrap
  display: flex
  position: fixed
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  background-color: rgba(246,243,241,0.7)
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 666


  .modal_window
    position: relative
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-color: white
    border-radius: 4px
    box-shadow: 0 0 10px 5px rgb(0 0 0 / 7%)
    padding: 50px 20px 40px 20px
    width: 561px
    min-height: 353px
    //max-height: 553px
    overflow: auto
    display: flex
    flex-direction: column




    .modal_title
      font-family: RobotoCondensedLight
      text-transform: uppercase
      font-size: 17px
      line-height: 24px
      color: grey
      letter-spacing: 5px


    .modal_content
      display: flex
      flex-direction: column
      flex: auto
      height: auto
      position: relative

