#CoursesPage, #EmployeesDetailPage
  position: relative

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .ant-tabs-nav
    margin-bottom: 0

  .ant-tabs-tab
    border-top-left-radius: 0
    border-top-right-radius: 10px
    background-color: #9b9b9b38
    border: 0
    margin-right: 4px

  .ant-tabs-tab:hover
    color: black !important
    background-color: rgba(155, 155, 155, 0.09)


  .ant-tabs-tab-active
    background-color: #fafafa !important
    border-bottom-color: #fafafa

    div
      color: rgba(0, 0, 0, 0.85)


  .ant-tabs-tab-btn
    font-family: RobotoCondensedLight
    font-size: 18px

  .flexCenter
    display: flex
    align-items: center


  .ant-table-tbody > tr > td
    vertical-align: middle

  .groupBlock
    font-family: RobotoCondensed
    color: #7b7b7b
    text-transform: uppercase


  .searchTable
    justify-content: flex-end
    align-items: center
    position: absolute
    right: 0px
    z-index: 2
    top: 40px

    .inputText
      padding: 7px 30px  11px 14px
      position: relative

    span
      font-family: RobotoCondensedLight
      color: #7b7b7b
      margin-right: 10px

  .ant-empty-description
    display: none

  .yellowBlock
    background-color: #f2c037


  .statusMain
    position: relative
    text-align: left
    margin-left: 35px

    &.status_table
      &:before
        content: ""
        width: 20px
        height: 20px
        position: absolute
        top: 0
        left: -35px
        background-repeat: no-repeat


  .title_row_oneMonth
    .end_date_table
      background-color: #f2c037

    .status_table
      &:before
        background: url("../../../src/img/status/info2.svg")


  .title_row_no_status
    .status_table
      &:before
        background: url("../../../src/img/status/noStatus.svg")


  .title_row_overDay
    .end_date_table
      background-color: #c100156b

    .status_table
      &:before
        background: url("../../../src/img/status/wrong.svg")

  .title_row_normal
    .status_table
      &:before
        background: url("../../../src/img/status/ok2.svg")

  .widthStatic
    width: 350px

  .noWrapLine
    white-space: nowrap


.tableMin
  min-width: 900px

  .ant-table-cell
    padding: 16px 10px


