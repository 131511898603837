#newPosition
  position: relative

  .ant-select-selector
    background-color: #faf8f7
    height: 44px

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .goodLine
    width: 100%
    height: 50px
    display: grid
    grid-template-columns: 1fr 1fr
    border-top: 1px solid grey
    border-bottom: 1px solid grey
    align-items: center
    padding: 0 15px

  .edit_btn
    float: right


  .newBtn
    width: 200px
    float: right
    margin-bottom: 10px

  .areaAdmin
    height: 150px

  .gridList
    display: grid
    grid-template-columns: 1fr 3fr
    grid-gap: 15px

  .gridList2
    display: grid
    grid-template-columns: 1fr 1fr

  .listInner
    display: flex
    flex-direction: column

.w80
  height: 80%
  width: 80% !important

  .modal_content
    justify-content: space-between

.w60
  height: 80%
  width: 60% !important

  .modal_content
    justify-content: space-between

.posBtw
  display: flex
  justify-content: space-between

  .dep
    color: rgba(123, 123, 123, 0.49)