#shosPageAdmin
  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px



.editUserSelect3
  width: 100%
  border: 1px solid #ccc
  color: #1a1a1a
  height: 44px
  display: block
  border-radius: 2px
  box-sizing: border-box
  font-size: 19px
  line-height: 24px
  font-weight: 300
  letter-spacing: 0.9px
  outline: none
  transition: border-color 0.15s ease,background 0.15s ease
  font-family: RobotoCondensed !important

  &.empty_info2
    .ant-select-selector
      background-color: gold !important


  .ant-select-selection-item
    font-family: RobotoCondensed !important

  .ant-select-selector
    height: 44px !important
    box-shadow: 0 0 0 1px #fff
    background: #faf8f7 linear-gradient(180deg,rgba(40,40,40,0.17) 0%,rgba(40,40,40,0) 13%,rgba(40,40,40,0) 17%)


    .ant-select-selection-item
      line-height: 42px !important




.modal_content
  justify-content: space-between



.title_min
  font-family: RobotoCondensed !important
  font-size: 18px