#SchooseModal

  .itemMainTable
    width: 200px

  .grid2
    display: grid
    grid-template-columns: 1fr 1fr

  .grid3
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 15px

  .gridTitle
    font-family: RobotoCondensed
    font-size: 18px
    color: #7b7b7b
    margin-bottom: 20px
    margin-top: 15px
    text-align: center

  .activeBorder
    border: 1px solid grey


  .list1Name
    margin-bottom: 10px
    padding: 8px
    background-color: rgba(123, 123, 123, 0.22)
    border-radius: 10px
    cursor: pointer

    &.active
      background-color: gold


  .list1NameDef
    margin-bottom: 10px
    padding: 8px
    //background-color: rgba(123, 123, 123, 0.22)
    border-radius: 10px
    cursor: pointer

    &.active
      background-color: gold


  .countLine
    color: #7b7b7b

    .all
      margin-right: 10px

      .val
        color: black


  .itemNameCat
    font-family: RobotoCondensed
    font-size: 16px
    color: grey
    text-transform: uppercase


  .list1, .list2, .list3
    overflow-y: auto


  .list2, .list3
    display: flex
    flex-direction: column
    align-items: center