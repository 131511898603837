
#info_modal_id
  font-size: 19px
  line-height: 24px
  letter-spacing: 0.9px
  display: flex
  flex-direction: column
  justify-content: space-between
  flex: auto

  height: 100%
  padding: 30px 0px 0 0px
  overflow: auto


  .text_info
    font-family: RobotoCondensed
    color: grey
    font-size: 16px

  .responsibleDetail
    font-size: 14px

    .key
      font-family: RobotoCondensedLight
      color: grey
      letter-spacing: 2.7px
      font-size: 14px
      line-height: 26px
      text-transform: uppercase

    .value
      font-family: RobotoCondensedLight
      color: black