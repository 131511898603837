#FilesLoad

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .parentList
    display: grid
    grid-template-columns: 2fr 5fr
    grid-gap: 15px
