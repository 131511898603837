#shosPage, #shosPageAdmin

  &.topMilkShow
    &:after
      content: ""
      height: 50px
      width: 100%
      top: 74px
      left: 0
      position: fixed
      background: linear-gradient(180deg, #f6f3f1 0%, rgba(255, 255, 255, 0))

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px


  .clicked
    border-bottom: 2px dashed grey
    cursor: pointer


  .line
    margin-bottom: 5px

  .positionItem
    display: flex
    flex-direction: column
    position: relative

    &.positionLeft
      padding-left: 30px
      border-left: 1px solid black

      &:before
        content: ""
        width: 30px
        height: 1px
        background-color: black
        position: absolute
        left: 0
        top: 45px


  .positionItemInner
    border-radius: 10px
    display: flex
    padding: 10px
    cursor: pointer
    background-color: white
    box-shadow: 1px 1px 3px rgb(0 0 0 / 40%)
    //margin-bottom: 15px
    padding-right: 30px
    position: relative
    width: 425px
    margin-bottom: 25px


    &.lineBefore
      &:before
        content: ""
        width: 30px
        background-color: rgba(176, 176, 176, 0.5)
        height: 1px
        left: -30px
        position: absolute
        top: 45px

    &:hover
      box-shadow: 1px 1px 3px rgb(0 0 0 / 70%)





    .avatarBlock
      height: 70px
      width: 70px
      border-radius: 100%
      margin-right: 25px



    .infoByPos
      display: flex
      flex-direction: column
      line-height: 34px
      width: 300px

      .posName
        color: grey
        font-size: 16px
        font-family: RobotoCondensedLight
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

        &:hover:after
          //content: attr(data-title)
          //position: absolute
          //left: 20%
          //top: 30%
          //z-index: 1
          //background: rgba(255,255,230,0.9)
          //font-family: RobotoCondensedLight
          //font-size: 11px
          //padding: 5px 10px
          //border: 1px solid #333




      .posValue
        font-size: 20px
        font-family: RobotoCondensed
        color: black


  .seeMore
    height: 20px
    width: 20px
    cursor: pointer
    margin-left: 34px
    //background:
    //  image: url("./../../img/arrowDown3/arrowDown.svg")
    //background-size: cover


    &:hover
      .arrowDownFill
        fill: #d9a82b


    &:active
      .arrowDownFill
        fill: #cc9814


  .seeHide
    background:
      image: url("./../../img/arrowDown3/arrowDown.svg")
      repeat: no-repeat
    background-size: cover
    transform: rotate(180deg)


  .sesPosition
    position: absolute
    left: 0
    bottom: 3px

  .upBtn
    top: -21px
    transform: rotate(180deg)


  @keyframes slideInFromLeft
    0%
      right: -500px
    100%
      right: 10px


  @keyframes slideInFromRigth
    0%
      right: 10px
    100%
      right: -500px


  .bioBlock
    position: fixed
    bottom: 20px
    width: 500px
    top: 76px
    right: 10px
    background-color: #fff
    box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 20%)
    padding: 30px 30px
    z-index: 999
    border-radius: 15px
    //transition: right 5s ease
    animation: 0.5s ease-out 0s 1 slideInFromLeft

    &.hideBlock
      animation: 0.5s ease-out 0s 1 slideInFromRigth


    .content
      display: flex
      flex-direction: column

      .avatar_zone
        display: flex
        justify-content: center

      .content_name_full_block
        margin-bottom: 45px
        margin-top: 20px
        align-items: center
        display: grid
        grid-template-columns: auto 0.5fr
        text-align: end

        .name_full
          font-size: 24px
          font-family: RobotoCondensed
          color: black

        .status
          font-size: 18px
          font-family: RobotoCondensedLight
          color: grey
          float: right

        .statusHover
          text-transform : uppercase


      .info
        margin-bottom: 25px

        .leaderSpan
          border-bottom: 1px dashed #7b7b7b
          cursor: pointer

      .phone, .mail
        margin-bottom: 15px
        display: flex
        align-items: center

        img
          width: 20px
          height: 20px
          margin-right: 10px

        span
          color: #2f54eb
          font-size: 20px
          font-family: RobotoCondensedLight

  .search
    display: flex
    align-items: center
    position: relative

    .searchShos
      position: relative

      .clear
        width: 15px
        height: 15px
        position: absolute
        top: 13px
        right: 7px
        cursor: pointer


    .inputZone
      width: 300px

    .blockResult
      background-color: white
      border: 1px solid rgba(0, 0, 0, 0.26)
      display: flex
      flex-direction: column
      background: #faf8f7 linear-gradient(180deg, rgba(40, 40, 40, 0.17) 0%, rgba(40, 40, 40, 0) 13%, rgba(40, 40, 40, 0) 17%)
      font-size: 19px
      padding: 7px 0px 7px
      border-radius: 2px
      position: absolute
      width: 300px
      z-index: 3

      span
        line-height: 28px
        padding: 0 14px
        font-family: RobotoCondensedLight

        &:hover
          background-color: rgba(128, 128, 128, 0.27)
          cursor: pointer

    .key
      font-size: 18px
      font-family: RobotoCondensedLight
      margin-right: 20px
      letter-spacing: 2.7px
      line-height: 26px



.itemLine
  display: flex
  position: relative
  //align-items: center
  //margin-bottom: 20px


  .lineVertical
    width: 30px
    height: auto
    border-left: 1px solid rgba(176, 176, 176, 0.5)
    margin-left: 43px


  .noneLine
    width: 30px
    height: auto
    margin-left: 43px


  .lineAnger
    border-left: 0
    width: 30px
    height: 45px
    border-left: 1px solid rgba(176, 176, 176, 0.5)
    position: relative
    margin-left: 43px

    &:before
      position: absolute
      content: ""
      top: 0
      left: 0
      height: 25px
      width: 3px
      //background-color: green

.search_margin
  margin-left: 20px