#profilePage
  font-family: RobotoCondensedLight
  height: 100%
  padding: 10px 10%
  display: flex
  position: relative

  &.topMilkShow
    &:after
      content: ""
      height: 50px
      width: 100%
      top: 74px
      left: 0
      position: fixed
      background: linear-gradient(180deg, #f6f3f1 0%, rgba(255, 255, 255, 0))

  &>.title
    font-family: RobotoCondensed
    position: absolute
    color: grey
    font-size: 22px
    text-transform: uppercase
    letter-spacing: 6.7px

  .content
    justify-content: center
    align-items: center
    display: flex

    .rel
      .avatar
        cursor: pointer

      .grid
        display: grid
        grid-template-columns: 1fr 1fr






      .phone, .mail
        margin-bottom: 15px
        display: flex
        align-items: center

        img
          width: 20px
          height: 20px
          margin-right: 10px

        span
          color: #2f54eb
          font-size: 20px
          font-family: RobotoCondensedLight




  .seeMore
    position: absolute
    bottom: 40px
    cursor: pointer
    margin-left: auto
    margin-right: auto
    left: 0
    right: 0
    text-align: center

    &:after
      content: ""
      position: absolute
      text-align: center
      margin-left: auto
      margin-right: auto
      width: 24px
      height: 24px
      left: 0
      right: 0
      top: 21px
      background-image: url("../../img/doubleDown/base.svg")
      background-repeat: no-repeat


    &:hover:after
      background-image: url("../../img/doubleDown/hover.svg")
      background-repeat: no-repeat


    &:hover:active
      background-image: url("../../img/doubleDown/active.svg")
      background-repeat: no-repeat




.rel
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%

