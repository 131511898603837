#modal_wrap

  .grid
    display: grid
    grid-template-columns: 1fr 3fr


  .input_label
    align-self: center
    font-family: RobotoCondensed
    text-transform: uppercase
    font-size: 14px
    letter-spacing: 3px
    color: black

  .line
    display: flex
    justify-content: space-between


    //.cancel_btn
    //  font-size: 14px
    //  line-height: 20px
    //  color: #000
    //  letter-spacing: 3px
    //  border-bottom: 1px solid black
    //  cursor: pointer

    .line_row
      display: flex
      flex-direction: row
      align-items: center


    .line_info
      text-align: right
      color: grey
      font-family: RobotoCondensedLight
      font-size: 14px
      line-height: 18px
      font-weight: 300
      margin-right: 15px
      letter-spacing: 0.7px