#MoneyPage
  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .selectMoney
    width: 150px
    height: 30px
    font-family: RobotoCondensed
    font-size: 18px
    margin-right: 20px


  .dataMonth


  .selectDef
    color: #808080


  .selectOpt
    font-family: RobotoCondensedLight
    font-size: 18px
    color: black

  .ant-empty-description
    &:after
      content: 'Данные отсутствуют'
      position: absolute
      color: #ccc
      left: calc(50% - 56px)



.EstimatedTable
  td
    font-size: 16px