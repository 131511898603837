.rightZone
  display: flex
  align-items: center


  .ringWrap
    margin-right: 35px
    cursor: pointer

    .popUp2
      right: -20px

  .ring
    display: inline-block
    position: relative

  .defCursor
    cursor: default


.back_btn
  cursor: pointer