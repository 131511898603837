.text_area
  margin-top: 12px
  margin-bottom: 8px

  textarea
    width: 100%
    resize: none
    padding: 15px 10px


    &:focus-visible
      outline-color: unset !important


.modalBodyBlock
  font-size: 16px !important
  color: grey
  font-family: RobotoCondensed