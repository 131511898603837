#userPage
  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .goodLine
    width: 100%
    height: 50px
    display: grid
    grid-template-columns: 1fr 1fr
    border-top: 1px solid grey
    border-bottom: 1px solid grey
    align-items: center
    padding: 0 15px

  .edit_btn
    float: right


  .newBtn
    width: 200px
    float: right
    margin-bottom: 10px

  .rowStyleAdd
    background-color: #f6f3f1

    td
      border-bottom: 1px solid #ccc
      border-top: 1px solid #fff


    &:hover
      background-color: #e7e1e1 !important

      td
        background-color: #e7e1e1 !important

.rightZ
  display: flex
  justify-content: flex-end

.ml10
  margin-left: 10px