#EmployeesDetailPage
  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .avatarBlock
    height: 70px
    width: 70px
    border-radius: 100%
    margin-right: 25px

  .infoByPos
    display: flex
    flex-direction: column
    line-height: 34px

    .posName
      color: grey
      font-size: 16px
      font-family: RobotoCondensedLight


    .posValue
      font-size: 20px
      font-family: RobotoCondensed
      color: black

  .checkboxEmpl
    width: 18px
    height: 18px
    cursor: pointer

  .itemLine
    display: flex
    position: relative

  .positionItemInner
    border-radius: 10px
    display: flex
    padding: 10px
    cursor: pointer
    padding-right: 30px
    position: relative
    width: 425px
    margin-bottom: 25px

  .btns2
    display: flex
    flex-direction: row-reverse
    margin-bottom: 10px
    justify-content: flex-end
    position: absolute
    right: 0
    z-index: 2
    top: -53px

    .mr10
      margin-left: 10px

  .searchTable
    justify-content: flex-end
    align-items: center
    position: absolute
    right: 0
    z-index: 2
    top: -55px


    span
      font-family: RobotoCondensedLight
      color: #7b7b7b
      margin-right: 10px



  .flexCenterVert
    display: flex
    align-items: center

  .tableClass

  .compEmplPage
    .ant-empty-description
      display: block
      opacity: 1
      color: white
      position: relative


      &:after
        content: 'Все компетенции проверены'
        position: absolute
        color: #ccc
        left: calc(50% - 85px)


