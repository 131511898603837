.popUpExit
  position: absolute
  padding:  10px 24px
  background-color: white
  border: 1px solid rgba(0,0,0,.15)
  margin-top: 15px
  top: 20px
  right: 5px
  display: flex
  flex-direction: column
  border-radius: 4px
  box-shadow: 0 2px 10px rgb(0 0 0 / 8%)

  &:before
    content: ""
    position: absolute
    width: 0
    height: 0
    border: 10px solid transparent
    border-bottom-width: 15px
    border-bottom-color: #fff
    right: 23px
    top: -20px


  .popUp_item
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    cursor: pointer


    &:nth-last-child(1)
      border-top: 1px solid #d9d9d9

    .popUp_item_title
      font-size: 14px
      line-height: 16px
      letter-spacing: 2.2px
      //color: #333333
      text-transform: uppercase
      cursor: pointer
      font-family: RobotoCondensed
      padding: 20px 0
      position: relative


    &.color_rev
      span
        color: #999999

    &.color_rev:hover
      span
        color: #333333

      #Layer_admin
        path
          fill: #333333

    &.color_rev:active
      span
        color: #666666

      #Layer_admin
        path
          fill: #666666



    .popUp_icon
      width: 17px
      height: 17px
      margin-left: 20px
