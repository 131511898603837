.modalInfoSave
  display: flex
  flex-direction: column
  justify-content: space-between
  height: auto
  flex: auto
  padding: 30px 0px 0 0px


  .modalInfoSave_text
    line-height: 24px
    font-family: RobotoCondensed
    color: grey
    font-size: 16px