#left_menu
  width: 80px
  height: 100%
  background-color: #fff
  display: flex
  align-items: center
  position: relative
  background-color: rgba(0, 0, 0, 0)
  transition-duration: .3s
  transition-timing-function: ease-in

  .menu
    height: 100%
    background-color: white
    width: 100%
    padding-top: 76px
    padding-left: 27px

    .menu_item_line
      height: 40px
      display: flex
      align-items: center
      padding-bottom: 5px
      //justify-content: center
      cursor: pointer

      &:hover
        span
          color: #d9a82b !important

      &.active
        span
          font-family: RobotoCondensed
          color: #000 !important

      &:active
        span
          color: #dabe78 !important


      .show_title_hide
        display: none
        position: absolute
        left: 85px
        background-color: rgba(123, 123, 123, 1)
        border-radius: 4px
        color: white
        padding: 3px 10px
        white-space: nowrap

        transition: all 1300ms ease-in-out

        &:before
          content: ''
          position: absolute
          border: 13px solid transparent
          border-right: 11px solid rgba(123, 123, 123, 1)
          left: -16px
          top: 0
          border-radius: 9px


        &.show
          display: block
          transition: all 300ms ease-in-out


      .menu_item
        font-size: 20px
        font-family: RobotoCondensedLight
        color: #737373
        white-space: nowrap
        opacity: 0
        margin-left: 10px

        &.hideItem
          visibility: hidden

        &:hover
          color: #ffd200

      .menu_item_logo
        margin-left: 29px



  .pin
    position: absolute
    bottom: 15px
    left: 24px
    width: 25px
    height: 25px
    background-size: cover
    background-repeat: no-repeat
    cursor: pointer

    &.lock
      background-image: url("../../img/pin/lock.png")


    &.unlock
      background-image: url("../../img/pin/unlock.png")


  .company_icon
    position: absolute
    top: 11px
    left: 15px
    width: 50px
    height: 50px
    padding: 15px
    //background-color: rgba(93, 90, 90, 0.76)
    background-image: url("../../img/logo.png")
    background-position: 15px 15px
    background-repeat: no-repeat
    cursor: pointer

    &:after
      content: attr(data-attr)
      position: absolute
      top: 11px
      width: 300px
      left: 43px
      font-size: 20px
      font-family: RobotoCondensedLight
      color: #737373





.left_menu_width.full
  width: 320px !important

  .menu_item_line

    .menu_item
      transition-duration: 1s
      opacity: 1 !important



.logotip
  position: absolute
  top: 15px