.inputText
  display: block
  width: 100%
  border: 1px solid #ccc
  color: #1a1a1a
  border-radius: 2px
  height: 44px
  box-sizing: border-box
  box-shadow: 0 0 0 1px #fff
  background: #faf8f7 linear-gradient(180deg,rgba(40,40,40,0.17) 0%,rgba(40,40,40,0) 13%,rgba(40,40,40,0) 17%)
  font-size: 19px
  line-height: 24px
  font-weight: 300
  padding: 7px 14px 11px
  letter-spacing: 0.9px
  outline: none
  transition: border-color 0.15s ease,background 0.15s ease
  font-family: RobotoCondensedLight


.input_error
  background: #ffd519 linear-gradient(180deg,rgba(40,40,40,0.17) 0%,rgba(40,40,40,0) 13%,rgba(40,40,40,0) 17%)


.wrong_mail
  position: relative

  &:after
    content: 'Не верный формат'
    display: block
    position: absolute
    top: -15px
    color: grey
    padding-left: 20px
    font-size: 14px
    line-height: 20px
    font-weight: 300
    letter-spacing: 0.7px
    margin: -4px 0 8px
    font-family: RobotoCondensedLight


.empty_info
  position: relative

  &:after
    content: 'Заполните поле'
    display: block
    position: absolute
    top: -15px
    color: grey
    padding-left: 20px
    font-size: 14px
    line-height: 20px
    font-weight: 300
    letter-spacing: 0.7px
    margin: -4px 0 8px
    font-family: RobotoCondensedLight

.searchWrapMain
  position: relative

  .lupe, .clear
    position: absolute
    display: block
    width: 15px
    height: 15px
    background-repeat: no-repeat
    background-size: cover
    top: 13px
    right: 7px

  .lupe
    background-image: url("./../../img/lupe.svg")

  .clear
    background-image: url("./../../img/close/base.svg")
    cursor: pointer

    &:hover
      background-image: url("./../../img/close/hover.svg")

    &:active
      background-image: url("./../../img/close/active.svg")



