.paginationForTable
  display: flex
  align-items: center
  justify-content: flex-end
  margin-top: 7px

  span
    font-size: 14px
    color: #7b7b7b
    font-family: RobotoCondensedLight
    letter-spacing: 3px
    text-align: center

  .pagSelect
    .ant-select-selector
      background-color: rgba(0,0,0,0)
      border: 0

    .ant-select-selection-item
      color: black
      font-size: 14px
      font-family: RobotoCondensed


  .countAll
    padding-left: 15px
    padding-right: 15px


  .prev, .next
    height: 15px
    width: 20px
    color: #7b7b7b
    background-image: url("./../../img/pagination/base.svg")
    background-repeat: no-repeat
    cursor: pointer

  .prev, .next
    &.active
      background-image: url("./../../img/pagination/active.svg")


  .next
    transform: rotate(180deg)


  .arrDown
    padding-left: 13px
    padding-right: 19px
    width: 10px
    height: 10px
    cursor: pointer

    &:after
      content: ""
      display: block
      position: absolute
      right: 6px
      top: 6px
      width: 10px
      height: 10px
      background-image: url("./../../img/pagination/down.svg")
      background-size: contain
      background-repeat: no-repeat


  .selectCount
    position: relative

    .popUpPagination
      width: 300px
      border: 1px solid #d9d9d9
      background-color: white
      position: absolute
      top: -126px
      left: -100px
      z-index: 111

      div
        font-size: 19px
        font-family: RobotoCondensedLight
        color: #1a1a1a
        padding: 9px 14px 9px
        letter-spacing: 0.9px
        border-bottom: 1px solid #d9d9d9
        cursor: pointer

      .activeEl
        color: #ffd200

