#EmployeesPage

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .gridTable
    display: grid
    grid-template-columns: 2fr 1fr 1fr
    align-items: center

    .freeLine
      margin-bottom: 50px

    .gridTable_title
      font-family: RobotoCondensedLight
      color: #808080
      font-size: 18px


    .avatarBlock
      height: 70px
      width: 70px
      border-radius: 100%
      margin-right: 25px

    .infoByPos
      display: flex
      flex-direction: column
      line-height: 34px

      .posName
        color: grey
        font-size: 16px
        font-family: RobotoCondensedLight


      .posValue
        font-size: 20px
        font-family: RobotoCondensed
        color: black

    .table_status
      width: 30px
      height: 30px
      background-size: cover
      //background-color: red

      &.ok
        background-image: url("./../../../src/img/status/ok2.svg")

      &.wrong
        background-image: url("./../../../src/img/status/wrong2.svg")

      &.exclamation
        background-image: url("./../../../src/img/status/info2.svg")

    .table_center
      justify-self: center


  .itemLine
    display: flex
    position: relative

  .positionItemInner
    border-radius: 10px
    display: flex
    padding: 10px
    cursor: pointer
    padding-right: 30px
    position: relative
    width: 425px

    &:hover
      //box-shadow: 1px 1px 3px rgb(0 0 0 / 40%)
      background-color: rgba(0, 0, 0, 0.11)

    &:hover:after
      content: 'Посмотреть'
      position: absolute
      border-radius: 10px
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(229, 227, 227, 0.5)
      text-align: center
      padding-top: 33px
      font-family: RobotoCondensedLight
      font-size: 22px
      color: #000000



