.upSettings
  position: fixed
  top: 19px
  z-index: 888
  display: flex
  padding-left: 10%
  align-items: center
  visibility: hidden

  &.single
    padding-left: 0


  &.visibilityShow
    visibility: visible

  .back_btn
    background: url("../../img/arrowLeft/base.svg") no-repeat
    width: 31px
    height: 31px
    cursor: pointer

    &:hover
      background: url("../../img/arrowLeft/hover.svg") no-repeat

    &:active
      background: url("../../img/arrowLeft/active.svg") no-repeat

  .upMode
    cursor: pointer
    line-height: 24px
    margin-left: 8px
    padding-left: 24px
    position: relative

    &.single
      margin-left: 25px


    &:before
      padding-top: 5px
      content: ""
      position: absolute
      left: 0
      top: 3px
      width: 20px
      height: 20px
      background: url("../../img/arrowUp/base.svg") no-repeat

    &:hover
      color: #d9a82b
      &:before
        background: url("../../img/arrowUp/hover.svg") no-repeat

    &:hover:active
      color: #ffaa00
      &:before
        background: url("../../img/arrowUp/active.svg") no-repeat

