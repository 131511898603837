#StatisticsPage

  .pLeft
    padding-left: 50px
    position: relative

    &:before
      content: ""
      position: absolute
      background-image: url("../../img/statistic/pageIco.png")
      background-repeat: no-repeat
      width: 35px
      height: 32px
      margin-right: 10px
      display: block
      background-size: cover
      top: 18px
      left: 0

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px



  .statisticBlock
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .title
      font-family: RobotoCondensed
      color: #808080
      font-size: 16px
      text-transform: uppercase


  .statisticItem
    width: 203px
    height: 203px
    border-radius: 203px
    background-color: white
    box-shadow: 0px 5px 10px #e6e3e1
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    position: relative

    .add
      content: ""
      width: 15px
      height: 15px
      background-color: rgb(255 255 255)
      position: absolute
      left: 22px
      bottom: 22px
      transform: rotate(134deg)

      //&:after
      //  content: ""
      //  position: absolute
      //  width: 30px
      //  height: 20px
      //  background-color: #8a0d0d


    .count
      font-size: 87px
      font-family: RobotoCondensedLight
      color: #e6c573

    .text
      font-size: 20px
      font-family: RobotoCondensedLight
      color: #e6c573


    &.required
      .count,  .text
        color: #ff7e47



    .percentCircle
      position: absolute
      width: 50px
      height: 50px
      background-color: white
      box-shadow: 0 5px 10px #e6e3e1
      border-radius: 100%
      display: flex
      left: -16px
      bottom: -16px

      font-size: 27px
      line-height: 47px
      color: #d4cccb

      justify-content: center
      align-items: center


  .selectDepartment
    width: 300px





  .pos0
    position: relative


  .pos1
    position: absolute
    left: 0
    top: 0
    transform: rotate(90deg)

  .pos2
    position: absolute
    left: 15px
    top: 15px

  .gridStatistic
    display: grid
    grid-template-columns: 1fr 1fr


  .peopleCount
    font-family: RobotoCondensed
    font-size: 16px
    color: #808080

    span
      font-size: 50px
      color: #E5B844
      font-family: RobotoCondensedLight
      margin-right: 10px

  .pr
    position: relative
    display: flex
    flex-direction: column

    .svgItem
      position: relative
      float: left

      display: flex
      width: 236px
      align-items: center
      justify-content: center



  .element_prosent
    position: absolute
    top: -11px
    left: 0
    font-family: RobotoCondensedLight
    color: #E5B844

    .mainProsentCount
      font-size: 60px

    .prosent
      font-size: 16px


  .element_name
    position: absolute
    display: flex
    flex-direction: column
    align-items: center

    span
      font-family: RobotoCondensed
      font-size: 16px
      color: #808080
      text-transform: uppercase

  .element_people
    position: absolute
    left: 249px
    top: 95px

    span
      font-family: RobotoCondensed
      font-size: 14px
      color: #808080


  .center
    align-items: center
    margin-bottom: 20px

  .statistic_circle
    width: 10px
    height: 10px
    border-radius: 100%
    margin-right: 35px

    &.itemFirst
      background-color: #E5B844

    &.itemSecond
      background-color: #BFB9B6


  .statistic_info
    font-family: RobotoCondensed
    font-size: 16px
    color: #808080

  .statistic_info2



.statItem
  position: relative
  width: 260px
  height: 260px

  .mainProsent
    position: absolute
    font-size: 40px

  .mainName
    position: absolute
    font-size: 20px
    top: 120px
    left: 30px

  .divOval2
    width: 260px
    height: 260px
    border-radius: 100%
    position: absolute
    background: linear-gradient(90deg, #0003dc 50%, #cde105 50%)


  .divOval
    width: 250px
    height: 250px
    border-radius: 100%
    position: absolute
    left: 5px
    top: 5px
    background: linear-gradient(90deg, #8f8f8f 50%, #ff0000 50%)

  .div1
    width: 125px
    height: 125px
    background-color: #f6f3f1
    position: absolute
    left: 5px
    top: 5px

  .div2
    width: 240px
    height: 240px
    background-color: #f6f3f1
    border-radius: 100%
    position: absolute
    left: 10px
    top: 10px



@keyframes dashoffset
  0%
    stroke-dashoffset: 500
  100%
    stroke-dashoffset: 100


.circle1, .circle3
  stroke-dasharray: 370
  stroke-dashoffset: 370

.circle2, .circle4
  stroke-dasharray: 500
  stroke-dashoffset: 500

  //animation: 1s ease-out 0s 1 dashoffset
