#competenciesPage,  #EmployeesDetailPage
  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .iconWrap
    display: flex
    justify-content: center
    cursor: pointer

  .icon
    width: 30px
    height: 30px
    background-size: cover
    background-image: url("../../img/email.png")


.ant-table-thead
  font-family: RobotoCondensed


.infoBtn
  background:
    image: url("../../img/infoBase.svg")
    repeat: no-repeat
  width: 14px
  height: 14px
  background-size: cover
  display: inline-block
  margin-left: 5px
  cursor: pointer

  &:hover
    background:
      image: url("../../img/info.svg")
      repeat: no-repeat

.flexCenter
  display: flex
  align-items: center

.centerColumn
  text-align: center !important


.greenRow
  .ant-select-selector
    background-color: green !important

.redRow
  .ant-select-selector
    background-color: #c100156b !important


.sendToApproveRow
  .ant-select-selector
    background-color: #9b9b9b38 !important

.sendToApproveRowChild
  .ant-select-selector
    background-color: #ffd20042 !important


.active_row
  background-color: #ffd20042 !important

  td
    background: #ffd20042 !important

  .ant-select-selector
    background-color: #ffd20042 !important

  &:hover
    background: #ffd20042 !important
    td
      background: #ffd20042 !important





.widthStatic2
  //white-space: nowrap

.tableMin
  min-width: 900px

  .ant-table-cell
    padding: 16px 10px

.ant-select-disabled

  .ant-select-selector
    cursor: default !important

