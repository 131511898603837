#usersList
  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px


.eyesvg3
  fill: black
  width: 25px !important
  height: 19px !important
  cursor: pointer

  &:hover
    fill: #d9a82b

  &:active
    fill: #cc9814

