.photoZone
  min-height: 200px
  width: 100%
  margin-bottom: 10px
  display: flex
  justify-content: center


.photoZonePrev
  width: 300px
  height: 200px


.modalPhotoZone
  display: flex
  flex-direction: column
  
  input
    position: absolute

.loadImg
  height: 190px
  border: 4px dashed grey
  border-radius: 20px
  width: 190px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

