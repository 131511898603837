#CompetenciesAdmin
  position: relative


  .addBtnAdmin
    position: absolute
    right: 0
    top: 10px

  .title
    font-family: RobotoCondensed
    color: #808080
    font-size: 22px
    line-height: 34px
    text-transform: uppercase
    letter-spacing: 6.7px

  .areaAdmin
    height: 150px

  .grid2
    display: grid
    grid-template-columns: 1fr 1fr




  .itemWrap

  .items
    margin-top: 25px
    overflow: scroll

  .item
    width: 90%
    height: 50px
    margin-bottom: 5px
    padding: 15px
    border-radius: 15px
    cursor: pointer
    text-transform: uppercase

  .activeL
    background-color: #7b7b7b82


  .columnName
    font-family: RobotoCondensed
    font-size: 18px
    color: #7b7b7b



  .addNewItem
    display: flex
    align-items: center
    position: absolute
    bottom: 0


  .leftZone, .rightZoneAdmin
    height: 500px
    position: relative



