

#history_modal_id

  height: 100%
  padding: 30px 5px 0 5px
  overflow: auto

  .btnClose
    float: left

  .history_item
    margin-bottom: 25px

    //&:nth-last-child(0)
    //  margin-bottom: 55px

    .body
      border-radius: 10px
      box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 14%)
      padding: 15px 15px


      .text
        margin-top: 15px
        color: grey

      .history_info_item
        display: grid
        grid-template-columns: 1fr 1fr
        justify-items: stretch
        font-family: RobotoCondensedLight
        font-size: 14px

        .justEnd
          justify-self: end

        .green
          color: #d9a82b

.btnBott
  align-items: center
  position: absolute
  bottom: 0
  width: 100%



.flexCenter
  display: flex
  justify-content: center