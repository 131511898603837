#SchooseModal
  display: flex
  flex: 1

  .grid2
    display: grid
    grid-template-columns: 1fr 1fr
    flex: 1

    .list1, .list2
      position: relative
      display: flex
      flex-direction: column
      align-items: center

    .searchZone
      position: absolute
      bottom: 0
      padding-bottom: 30px

  .list1Name
    margin-bottom: 10px
    padding: 8px
    background-color: rgba(123, 123, 123, 0.22)
    border-radius: 10px

    &.active
      background-color: gold

  .countLine
    color: #7b7b7b


    .all
      margin-right: 10px

      .val
        color: black

